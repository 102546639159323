import { useState } from 'react'
import type { Picture as PictureType } from '@etf1-interne/tf1info_types_news/generics'
import { mixins, theme } from '../../../styles'
import { PictureElementList } from '../PictureElementList'
import { Time } from '../Time'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { SVGIcon } from '../SVGIcon'
import { PreviewVideo } from '../PreviewVideo'
import { CategoryLabel } from '../CategoryLabel'

export type IArticleItem = {
  category?: {
    title?: string
    color?: string
  }
  date?: string
  hasMultiLive?: boolean
  icon?: string
  link?: string
  pictures?: PictureType | null
  title?: string
  sponsor?: {
    title?: string
    link?: string
    pictures?: PictureType | null
  }
  defaultCategoryColor?: string
  placeholderTheme?: 'dark' | 'light'
  iconColor?: string
  onClick?: () => void
  previewVideoUrl?: string
}

export function ArticleItem({
  category,
  date,
  defaultCategoryColor = theme.cssVars.deepBlue,
  hasMultiLive,
  icon,
  iconColor,
  link,
  pictures,
  placeholderTheme = 'light',
  title,
  sponsor,
  onClick,
  previewVideoUrl,
}: IArticleItem): JSX.Element {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const hasPicture = !!pictures
  return (
    <>
      <article
        className="ArticleItem"
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <h2 className="ArticleItem__Title">
          {hasMultiLive && (
            <SVGIcon
              name="direct"
              className="ArticleItem__DirectIcon"
              data-testid="ArticleItem__DirectIcon"
            />
          )}
          <a className="ArticleItem__Link" href={link} onClick={onClick}>
            {title}
          </a>
        </h2>
        <CategoryLabel
          title={category?.title}
          color={category?.color}
          defaultColor={defaultCategoryColor}
          className="ArticleItem__Category"
        />

        {hasPicture ? (
          <div className="ArticleItem__Picture">
            {icon && (
              <>
                <SVGIcon
                  name="play-circle"
                  className="ArticleItem__Play"
                  size="32px"
                  secondaryColor={theme.cssVars.white}
                />
                <SVGIcon
                  name="play-circle-hover"
                  className="ArticleItem__Play__hover"
                  size="37px"
                  secondaryColor={theme.cssVars.white}
                />
                <div className="ArticleItem__Play__hover__Dash" />
              </>
            )}
            {previewVideoUrl ? (
              <PreviewVideo
                url={previewVideoUrl}
                pictures={pictures}
                withIcon={false}
                isActive={isMouseOver}
              />
            ) : (
              <PictureElementList
                {...pictures}
                alt={pictures?.caption || title}
                placeholderTheme={placeholderTheme}
                aria-hidden
              />
            )}
          </div>
        ) : null}

        <footer className="ArticleItem__Footer flex items-center">
          {icon && !hasPicture ? (
            <Icon name="play" primaryColor={iconColor ?? theme.cssVars.deepBlue} size="16px" />
          ) : null}
          {date && (
            <time dateTime={date} className="ArticleItem__Date">
              <Time publishedAt={date} />
            </time>
          )}
          {sponsor?.pictures ? (
            <div className="ArticleItem__Sponsor flex items-center justify-center">
              {sponsor.title ? (
                <span className="ArticleItem__Sponsor__Label">{sponsor.title}</span>
              ) : null}
              <Link url={sponsor.link} onClick={onClick}>
                <PictureElementList
                  alt={sponsor.pictures.caption}
                  elementList={sponsor.pictures.elementList}
                  lazyload={sponsor.pictures.lazyload}
                  placeholderTheme="light"
                />
              </Link>
            </div>
          ) : null}
        </footer>
      </article>

      <style jsx>{`
        .ArticleItem {
          background-color: ${sponsor ? theme.cssVars.lightBlue : 'transparent'};
          padding: ${sponsor ? '15px' : '0'};
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleItem {
            padding: ${sponsor ? '46px' : '0'};
          }
        }

        .ArticleItem__Picture {
          padding-top: ${previewVideoUrl ? '0' : '56.25%'}; // ratio of 16/9
        }
      `}</style>
      <style jsx>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes pulse {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }

        @keyframes spin {
          100% {
            transform: rotateZ(360deg);
          }
        }

        .ArticleItem {
          display: grid;
          grid-template-areas:
            'category'
            'picture'
            'title'
            'footer';
        }

        .ArticleItem :global(.ArticleItem__Category) {
          grid-area: category;
          display: block;
          font-size: 14px;
          margin-bottom: 12px;
        }

        .ArticleItem__Link {
          text-decoration: none;
        }

        .ArticleItem__Link:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
        }

        .ArticleItem :global(.ArticleItem__Play__hover__Dash) {
          position: absolute;
          bottom: 17px;
          left: 17px;
          height: 30px;
          width: 30px;
          background-color: transparent;
          border-radius: 50%;
          background-color: ${theme.cssVars.deepBlue};
          z-index: 2;
        }

        .ArticleItem :global(.ArticleItem__Play__hover),
        .ArticleItem :global(.ArticleItem__Play__hover__Dash) {
          visibility: hidden;
          opacity: 0;
          animation: pulse 1s opacity;
        }

        .ArticleItem :global(.ArticleItem__Play__hover__Dash) {
          transition: all 2s ease-in-out;
        }

        .ArticleItem:hover :global(.ArticleItem__Play__hover),
        .ArticleItem:hover :global(.ArticleItem__Play__hover__Dash) {
          visibility: visible;
          opacity: 1;
        }

        .ArticleItem:hover :global(.ArticleItem__Play__hover) {
          animation: fadeIn 350ms;
        }

        .ArticleItem:hover :global(.ArticleItem__Play) {
          visibility: hidden;
          opacity: 0;
        }

        .ArticleItem:hover :global(.ArticleItem__Play__hover__Dash:before) {
          position: absolute;
          content: '';
          height: 35px;
          width: 35px;
          border: 1px dashed ${theme.cssVars.white};
          border-width: 1px;
          top: -3px;
          left: -3px;
          border-radius: inherit;
          animation: spin 10s linear infinite;
          z-index: 2;
        }

        .ArticleItem:hover .ArticleItem__Title {
          color: ${theme.cssVars.focusBlue};
        }

        .ArticleItem :global(.ArticleItem__DirectIcon) {
          margin-right: 5px;
          display: inline;
        }

        .ArticleItem__Picture {
          grid-area: picture;
          position: relative;
          width: 100%;
          margin-bottom: 12px;
          border-radius: 4px;
          overflow: hidden;
        }

        .ArticleItem__Picture :global(img) {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
        }

        .ArticleItem__Picture :global(.ArticleItem__Play) {
          position: absolute;
          display: inline-block;
          bottom: 16px;
          left: 16px;
          z-index: 3;
        }

        .ArticleItem__Picture :global(.ArticleItem__Play__hover) {
          position: absolute;
          bottom: 14px;
          left: 13.2px;
          z-index: 3;
        }

        .ArticleItem__Title {
          grid-area: title;
          color: ${theme.cssVars.deepBlue};
          margin: 0;
          font-size: 28px;
          display: inline;
        }

        .ArticleItem__Footer {
          grid-area: footer;
          margin-top: 5px;
        }

        .ArticleItem__Footer :global(.Icon) {
          padding-top: 2px;
          margin-right: 6px;
        }

        .ArticleItem__Date {
          color: ${theme.cssVars.midBlue};
          padding-top: 4px;
          ${mixins.fonts.base.xsmall};
        }

        .ArticleItem__Sponsor {
          float: right;
          margin-left: auto;
        }

        .ArticleItem__Sponsor__Label {
          font-weight: 600;
          font-size: 13px;
          line-height: 145%;
          color: ${theme.cssVars.deepBlue};
        }

        .ArticleItem__Sponsor :global(img) {
          height: 32px;
        }

        .ArticleItem__Sponsor :global(a) {
          height: 32px;
          margin: 0;
          margin-left: 10px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleItem__Title {
            font-size: 31px;
          }
        }
      `}</style>
    </>
  )
}
