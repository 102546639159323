import React from 'react'
import { theme } from '../../../styles'

export interface ICategoryLabel {
  title: string
  color: string
  defaultColor?: string
}

export function CategoryLabel({
  title,
  color,
  defaultColor,
  ...rest
}: ICategoryLabel & React.AllHTMLAttributes<HTMLDivElement>): JSX.Element {
  if (!title) {
    return null
  }

  const categoryColor = color || defaultColor || theme.cssVars.fontColor

  return (
    <>
      <small style={{ color: categoryColor }} {...rest}>
        {title}
      </small>
    </>
  )
}
